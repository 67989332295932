import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldSemmelweis: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you build Semmelweis?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_semmelweis.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you build Semmelweis?</h1>
          <h2>
            Not sure if building Semmelweis is the right move? No worries! Check
            our review and guide for her!
          </h2>
          <p>
            Last updated: <strong>14/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Build? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="semmelweis"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          Semmelweis is a powerful support who grants damage buffs in exchange
          for HP loss. Her [Blood Domain] provides up to +40% DMG Bonus that, by
          virtue of being an Array effect, can stack with other DMG Bonus buffs.
          If you enjoy high risk, high reward gameplay or lack a strong damage
          buffer, you should consider building Semmelweis. Just make sure to
          bring ample sustain because Semmelweis’ HP drain will make your team
          squishier.
        </p>
        <SectionHeader title="Lore" />
        <p>
          A young woman from Szeged, Semmelweis was originally a mixed-blood
          arcanist whose arcane lineage was so faint she couldn’t cast arcane
          skills. This allowed her to blend in as a human when she joined the
          St. Pavlov Foundation. During Semmelweis’ time as a Foundation
          investigator, a doctor at the Laplace Rehabilitation Center informed
          her that she was afflicted with an extremely rare condition. With only
          three to six months left to live, Semmelweis refused to stop working
          and instead transferred to the History Maintenance Team—a notoriously
          dangerous job.
        </p>
        <p>
          Version 1.6’s Echoes in the Mountain details Semmelweis’ time
          investigating a branch of Manus devotees in the mountains as her
          health began to fail. At the end of her investigation, she received a
          letter from the mysterious “Lorelei of the Rhine,” who promised a cure
          for her condition in Vienna.
        </p>
        <SectionHeader title="Review" />
        <p>
          Semmelweis is a 6-star Mineral Reality character who functions as an
          damage-buffing Support. She provides powerful buffs at the cost of
          HP—a kit befitting of a vampire.
        </p>
        <p>
          Semmelweis’ buff incantation “Proper Means” grants everyone a DMG
          Dealt buff and allows her to designate one ally as [Fresh Blood]:
          before actively casting an incantation, this ally loses some HP and
          gains +15% Critical Rate for this action. Semmelweis drains her own
          HP, too—her basic attack “Iron Nameplate” has very high multipliers at
          the cost of 10% of her Current HP.
        </p>
        <p>
          All of these instances of HP drain allow Semmelweis’ teammates to take
          full advantage of her [Blood Domain] Array. Under [Blood Domain],
          allied characters gain DMG Taken Reduction and +5% DMG Bonus for each
          10% of their HP they are missing. Semmelweis creates this Array
          through her Ultimate, “As the Moon Rises,” which drains 20% of all
          allies’ Current HP and causes her to enter the [Feral] status for 3
          rounds—the same duration as her Array. During these 3 rounds, [Feral]
          causes Semmelweis to cast a mass attack [Truth Revealed] at the end of
          each round. [Truth Revealed] scales by +30% Reality DMG per instance
          of HP loss when characters take action, stacking up to a whopping
          +600% for a total multiplier of 680% at 20 stacks (which is admittedly
          very difficult to achieve).
        </p>
        <p>
          A powerful support whose DMG Bonus and Critical Rate buffs can aid the
          vast majority of DPS characters, Semmelweis is a universally
          applicable teammate for players looking to increase their team’s
          damage in exchange for survivability. Semmelweis also enables the new
          self-damage team archetype by providing a potent payoff for HP drain
          through [Blood Domain]’s strong DMG Bonus scaling. Although primarily
          an offensive support, Semmelweis certainly doesn’t lack in the damage
          department herself. On top of that, Semmelweis has plenty of Moxie to
          set up her Array. Her Insight I continues the trend of Ultimate-Array
          casters gaining +3 Moxie when entering battle first seen in Getian’s
          kit. However, unlike her predecessor, she gains +1 Moxie upon casting
          her Ultimate, allowing her to comfortably recast her Array every 3
          turns without needing to move incantations or refresh her 2-turn buff
          prematurely.
        </p>
        <p>
          By casting her buff and attack incantations on Round 1, Semmelweis can
          cast her Ultimate as early as Round 2. After that, she can achieve
          100% uptime on [Blood Domain] by reapplying [Fresh Blood] every other
          turn and attacking 2–3 times between each Ultimate to replenish Moxie.
        </p>
        <p>
          In conclusion, Semmelweis is a formidable Support character who is
          relatively simple to play. If you have some extra Stamina, like
          playing self-damaging characters, or are in need of some extra damage,
          consider building your free copy of Semmelweis.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Universal support with powerful DMG Bonus and Crit Rate
                    buffs.
                  </li>
                  <li>Array effect stacks with other status effects.</li>
                  <li>
                    High personal damage multipliers and access to both AoE and
                    single-target attacks. Can apply [Fresh Blood] to herself
                    for a more DPS playstyle.
                  </li>
                  <li>
                    Synergizes well with self-damaging characters, giving new
                    life to old characters like Eternity.
                  </li>
                  <li>Simple rotation and easy-to-maintain Array.</li>
                  <li>Free in 1.9!</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    High HP tax that requires a substantial amount of sustain;
                    makes your team more vulnerable to burst damage.
                  </li>
                  <li>
                    Not that many self-damaging characters available as of 1.9,
                    so stacking up [Truth Revealed] takes time.
                  </li>
                  <li>
                    Not the most AP-positive—casts around 2 incantations per
                    round for 100% uptime. HP drain may mean your Survival unit
                    also needs to use more AP to keep team alive.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Semmelweis works fine at P0.</p>
        <p>P1 increases her Array’s DMG Bonus.</p>
        <p>
          “As the Moon Rises: Deployed [Blood Domain]'s effect is now: Whenever
          an ally attacks, for every 10% of their missing HP, gain DMG Bonus
          +7.5% (up to 60%).”
        </p>
        <p>
          Her first Portray allows you to get 1.5 times more DMG Bonus in
          exchange for Lost HP. You get more DMG Bonus at 40% HP (+45%) than you
          would get at 20% HP with P0 (+40%, the maximum).
        </p>
        <SectionHeader title="Great Synergies" />
        <p>Any DPS who likes DMG Bonus and Crit Rate.</p>
        <p>
          Self-damaging characters like Eternity, Ezra Theodore, Kakania, and
          upcoming J can capitalize upon Semmelweis’ [Blood Domain] well.
          Balloon Party’s Lost HP%-based healing works nicely.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="eternity"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="ezra-theodore"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="balloon-party"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="kakania"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="j"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Notable Anti-Synergies" />
        <p>
          <strong>Array Characters:</strong> You can only have 1 Array active at
          a time, so Getian, Tuesday (2.1), and Lopera (2.2) will not work with
          Semmelweis.
        </p>
        <p>
          <strong>+DMG Bonus% Buffers:</strong> While it may be tempting to run
          a DMG Bonus buffer to stack with [Blood Array]’s effect, Semmelweis’
          “Proper Means” grants a generic +DMG Bonus% [Stats Up] that will
          compete with other buffs of the same name—like the ones from An-an
          Lee, Necrologist, Pickles, and Argus (2.1), for example.
        </p>
        <p>
          <strong>HP-Balancing:</strong> Characters like Charlie and Pavia who
          want to remain at a specific health threshold will have a hard time
          with Semmelweis’ HP drain.{' '}
        </p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on her:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="from-the-depths"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="long-night-talk"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="blasphemer-of-night"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="in-the-shadow"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Obtainable from the 2.0 roguelike mode, “From the Depths” is
          Semmelweis’ signature psychube. Because her Ultimate damages all
          allies, it can activate this psychube’s passive and grant Semmelweis
          the [Echoes of Life] status, which sets her to 33% HP after taking
          lethal damage. Each instance of self-damage on the team increases the
          carrier’s Incantation Might; at maximum stacks, the psychube offers a
          truly enormous +39% Incantation Might. This psychube also offers
          excellent Reality DEF and Mental DEF, making Semmelweis more resistant
          to damage.
        </p>
        <p>
          “Long Night Talk” is a decent option for Semmelweis, especially if you
          already have it built. While she cannot activate the “2+ [Pos Status]”
          passive on her own ([Feral] counts, but [Fresh Blood] is Special), she
          does cast a lot of buffs and benefits from the ATK% increase.
          Semmelweis also makes good use of its base 18% Incantation Might.
        </p>
        <p>
          As always, “Blasphemer of Night” is a good damage option if you can
          activate its conditions. Semmelweis doesn’t inflict any [Neg Status]
          on her own, but she benefits from the Incantation Might, though the
          extra DMG Dealt might reach diminishing returns.
        </p>
        <p>
          Available in 1.9 from her Anecdote, “In the Shadow” is a tailored
          5-star option for Semmelweis. At full stacks, it offers a very solid
          12% DMG Reduction, +10% ATK, and 15% Incantation Might. Be warned,
          however: this is a hyper-specific psychube that can only be fully
          utilized by Semmelweis (and Bette, weirdly enough).
        </p>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/semmelweis_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/semmelweis_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/semmelweis_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/semmelweis_r_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Double Vampire
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="eternity"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="semmelweis"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="balloon-party"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>
                  Bkornblume, Isolde, Ezra Theodore, Kakania, Any Other Healer.
                </strong>
              </li>
              <li>
                An affordable mono-Mineral Reality team for people who want to
                play Eternity again, just like the old days. Eternity’s
                self-damaging mechanics synergize very well with those of her
                fellow vampire Semmelweis. Balloon Party’s healing scales off of
                Lost HP%, making her a great sustain for this team. In 4-P
                content, you can add Bkornblume for extra nostalgia.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Healing? What Healing?<span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="semmelweis"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="kakania"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="ezra-theodore"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Any Healer, Isolde</strong>
              </li>
              <li>
                While Semmelweis’ self-damaging effects do not count toward
                Kakania’s [Empathy] stacks, Kakania’s tankiness and damage
                redirection helps to make Semmelweis less squishy. Kakania’s
                “Association” buff and both of Ezra’s incantations have
                self-damaging effects that help Semmelweis increase the damage
                of [Truth Revealed]. Ezra’s Shields also help to keep the team
                healthy. In 4-P, you can do the responsible thing and add a
                healer or go in guns blazing with Isolde. Isolde’s buffs and
                debuffs notably have no overlap with Semmelweis’, making her a
                great supplementary support.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            The 1.9 Special <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="semmelweis"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Kakania, Isolde, A Knight, 6</strong>
              </li>
              <li>
                Realistically, you can switch out Lucy for any DPS and Vila for
                any Sustain and you’ll get a functional team because Semmelweis
                is so universal. Lucy has really high skill multipliers, making
                Semmelweis’ DMG Bonus a welcome addition. Additionally, Lucy’s
                psychube gives +18% Incantation Might if the user is under 2+
                [Special] statuses; Lucy can grant herself [Dynamo] easily, but
                her other [Special] conditions require defeating an enemy or
                casting her Ultimate. As a [Special] status, [Fresh Blood]
                allows Lucy to access that Incantation Might immediately. In 4-P
                content, add Kakania for the full 1.9 experience.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldSemmelweis;

export const Head: React.FC = () => (
  <Seo
    title="Should you build Semmelweis? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if building Semmelweis is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
